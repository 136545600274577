<template>
  <div>dev version</div>
</template>

<script>
export default {
  name: "DevTitle"
}
</script>

<style lang="scss" scoped>
div {
  width: 100vw;
  height: 30px;
  z-index: 1000;
  position: fixed;
  top: 0;
  background-color: #ffeeba91;
  color: #090909;
  text-align: center;
  font-size: 20px;
}
</style>