<template>
    <div>
        <DevTitle style="display: none"/>
        <Hello/>
        <Resume :projects="projects" :skills="skills" :about="about"/>
        <Footer/>
    </div>
</template>

<script>
import Vue from 'vue'

import Hello from "/src/components/Hello";
import Resume from "/src/components/Resume/Resume";
import Footer from "/src/components/Footer";
import DevTitle from "/src/components/DevTitle";
import {LayoutPlugin} from 'bootstrap-vue'

export default {
    name: "HomeView",
    components: {Hello, Resume, Footer, DevTitle},
    inject: ["loadingScreen"],
    created() {
        Vue.use(LayoutPlugin)
    },
    mounted() {
        // alert(window.screen.width);
        document.onreadystatechange =
            () => this.documentStateChanges(document.readyState, this)
    },
    methods: {
        documentStateChanges: async (state, component) => {
            if (state !== "complete") return;

            // console.log(component.loadingScreen.enabled);
            let dataResponse = await fetch("/data/data.json");
            let data = {}

            try {
                if(!dataResponse.ok) {
                    throw new Error("HTTP ERROR")
                }
                data = await dataResponse.json()
            } catch (error) {
                console.warn(`Fallback to predefined data: ${dataResponse.status} - ${error}`)
                dataResponse = await fetch("/cv_data.json");
                data = await dataResponse.json()
            }

            const expYear = new Date().getFullYear() - 2014;

            component.about = data.about.replace("${getExperienceYears()}", expYear)
            component.skills = data.skills
            component.projects = data.projects.map((p) => {
                p.company = data.company[p.company]
                return p
            });

            component.loadingScreen.enabled = false;

        }
    },
    data() {
        return {
            projects: [],
            skills: [],
            about: "about",
            cvPath: "/data/cv.pdf"
        }
    },
}
</script>

<style scoped lang="scss">
@import "src/assets/media-queries";

</style>